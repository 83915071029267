var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-4",attrs:{"id":"article-tabbed-content"}},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-4"},[_c('b-input-group',{staticClass:"search_group my-2",attrs:{"size":"md"}},[_c('b-form-input',{staticClass:"article_search py-2",attrs:{"placeholder":'Search Articles...'},model:{value:(_vm.search_text),callback:function ($$v) {_vm.search_text=$$v},expression:"search_text"}}),_c('b-input-group-append',[_c('b-button',{staticClass:"article_search_button"},[_c('b-icon-search')],1)],1)],1)],1)]),(_vm.computedData.length)?_c('div',{staticClass:"row my-4 py-2 cards-row"},_vm._l((_vm.computedData),function(d,index){return _c('div',{key:index,staticClass:"col-md-3"},[_c('b-card',{staticClass:"block_cards my-3 bg-transparent",on:{"click":function($event){return _vm.$router.push({
                   name: 'read-article',
                   params: {
                       art_id: d.id,
                       subcat_id: d.subcategory_details ? d.subcategory_details.id : '',
                       tab_index: 2
                   }
               })}}},[_c('b-card-title',{staticClass:"card_title"},[_vm._v(" "+_vm._s(d.title))]),_c('div',{staticClass:"latest-card-img-wrapper"},[_c('b-card-img',{attrs:{"src":_vm.baseURL+d.thumbnail}})],1),_c('b-card-sub-title',{staticClass:"text-truncate my-2"},[_vm._v(_vm._s(d.author_details.name))]),_c('b-card-text',{staticClass:"text-white card_date_label"},[_vm._v(" Date: "),_c('span',{staticClass:"card_date"},[_vm._v(_vm._s(d.date))])])],1)],1)}),0):_vm._e(),(!_vm.data.length)?_c('div',{staticClass:"text-white text-center"},[_c('span',{staticClass:"text-capitalize"},[_vm._v(" No "+_vm._s(_vm.content))]),_vm._v(" available under this category ")]):_vm._e(),(_vm.data.length)?_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-md-12"},[_c('b-pagination',{attrs:{"align":"center","total-rows":_vm.data.length-1,"per-page":_vm.pagination.per_view,"first-text":"First","next-text":"Next","prev-text":"Prev","last-text":"Last"},model:{value:(_vm.pagination.current_view),callback:function ($$v) {_vm.$set(_vm.pagination, "current_view", $$v)},expression:"pagination.current_view"}})],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }