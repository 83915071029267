<template>
    <div id="general-tabbed-content">
        <Loader :state="loader_state"></Loader>
        <b-card  v-for="(d,index) in computedData" :key="index" class="my-4 px-0 px-md-4 py-md-2 py-0 bg-transparent data-card" >
              <div class="row">
                  <div class="col-md-5">
                      <b-card-img :src="baseURL+d.thumbnail" alt="card_img" class="imageClass"  :class="{appsThumb: isAppContent  }"></b-card-img>
                  </div>
                  <div class="col-md-7">
                      <b-card-body class="p-sm-0">
                          <b-card-title class="card_title">
                             {{ d.title}}
                          </b-card-title>
                          <b-card-sub-title class="card_description my-3 h-5">
                              {{d.description}}
                          </b-card-sub-title>
                          <b-card-text>
                              <p v-if="content == 'playlist' || content == 'audio'" class="speaker author mt-4 mb-1 ">{{d.speaker_details ? d.speaker_details.name : null}}</p>
                              <p v-if="content == 'books'" class="speaker author mt-2 mb-0 ">{{d.author_details ? d.author_details.name : null}}</p>
                              <p v-if="content == 'books'" class="language mt-2 mb-0 "><span class="language_label">Language: </span>{{d.language_details ? d.language_details.name : null}}</p>
                              <p v-if="content=='audio'" class="total_video_label my-1">Total Audios:<span class="total_videos ml-2"> {{d.audios.length}} </span> </p>
                              <p v-if="content == 'playlist' || content == 'audio'" class="status_label mb-1">Status:<span class="status ml-2">{{d.status ? 'Completed' : 'InProgress'}}</span></p>
                              <div v-if="content=='apps'" class="my-4">
                                  <a v-if="d.android_link !== null" :href="d.android_link" target="_blank"><img class="store_logo my-2 mr-md-2" src="../../assets/icons/google-play.svg" alt="android_link"/></a>
                                  <a v-if="d.ios_link !== null" :href="d.ios_link" target="_blank"><img class="store_logo ml-md-2" src="../../assets/icons/app-store.svg" alt="ios_link"/></a>
                              </div>

                          </b-card-text>
                            <div class="row">
                                <div class="col-md-12">

                                    <router-link v-if="content=='playlist'" :to="{name:'watch-playlist', params: {
                                      pl_id: d.id,
                                      subcat_id: d.subcat_id,
                                      tab_index: 0
                                    }}">
                                        <b-button class="watch_button px-4 py-1 float-md-right">Watch Series</b-button>
                                    </router-link>

                                    <router-link v-if="content=='audio'" :to="{name:'listen-audio', params: {
                                         audioc_id: d.id,
                                         subcat_id: d.subcat_id,
                                         tab_index: 1

                                    }}">
                                        <b-button class="watch_button px-4 py-1 float-md-right">Listen Series</b-button>
                                    </router-link>

                                    <b-button v-if="content == 'books'" :href="baseURL+d.book" target='_blank' class="watch_button px-4 py-1 float-md-right"> Download </b-button>
                                </div>
                            </div>

                      </b-card-body>
                  </div>
              </div>
        </b-card>
        <div v-if="!data.length" class="text-white text-center">
            <span class="text-capitalize" v-if="content === 'playlist'"> No Video Series </span>
            <span class="text-capitalize" v-else-if="content === 'audio'"> No Audio Series </span>
            <span class="text-capitalize" v-else> No {{ content }} </span>
            available under this category
        </div>
        <div v-if="data.length" class="row mt-5">
            <div class="col-md-12">
                <b-pagination
                        align="center"
                        v-model="pagination.current_view"
                        :total-rows="data.length-1"
                        :per-page="pagination.per_view"
                        first-text="First"
                        next-text="Next"
                        prev-text="Prev"
                        last-text="Last"
                ></b-pagination>
            </div>
        </div>
    </div>

</template>

<script>
 import config from "../../assets/config";
 import Loader from '../shared/alhaq_loader'
 import { mapActions } from 'vuex'
 export default  {
        components: { Loader },
        props: {
            data: {
                type: Array,
                default: []
            },
            content: {
                type: String,
                default: ''
            },
            detail: Object
        },
     data() {
        return {
            loader_state: false,
            baseURL: config.baseURL,
            pagination: {
                per_view: 4,
                current_view: 1,
            },
        }
     },
     methods: {
         async getTotalVideos(url) {
             let length = null;
             this.loader_state = true
             this.getPlaylistsYoutube({
                 pl_link: 'https://www.googleapis.com/youtube/v3/playlistItems',
                 pl_id: url,
                 api_key: 'AIzaSyC8gJt4bEGHezrjs4Ten4w4CNd2Rusttvo'
             }).then (response => {
                 length = response.data.pageInfo.totalResults;
                 this.loader_state = false
             })
             return length;
         },
         ...mapActions(['getPlaylistsYoutube'])
     },
     computed: {
            computedData () {
                return this.data.filter((item, index) => (
                    index >= this.pagination.per_view * (this.pagination.current_view - 1) &&
                    index <= this.pagination.per_view * this.pagination.current_view
                ))
            },
            isAppContent () {
                return this.content=='apps'
            }
     }
 }

</script>

<style scoped>

    .data-card {
        border: .5px dashed white;
        border-width: thin;
    }
    .card_title {
        font-family: brandFont-bold;
        font-size: 26px;
    }
    .card_title,
    .speaker,
    .status,
    .total_videos,
    .language
    {
        color: #28abe1;
    }
    .card_description,
    .total_video_label,
    .status_label
    {
        font-family: brandFont-subcat;
        color: white !important;
    }
    .card_description {
        font-size: 16px;
    }
    .speaker,
    .total_video_label,
    .status_label {
        font-size: 16px !important;
    }

    .total_videos,
    .status
    {
        font-size: 14px;
        font-family: brandFont-bold;
    }
    .watch_button {
        outline: none;
        color: black;
        background-color: #ffffff !important;
        box-shadow: 0px 4px 4px 3px #330d0ded;
        font-size: 14px;
        font-family: brandFont !important;
        transition: box-shadow .2s ease-in-out;
    }
    .watch_button:active, .watch_button:hover {
        background-color: #28abe1 !important;
        color: white !important;
        outline: none;
        box-shadow: none !important;
    }
    .speaker {
        font-family: brandFont-subcat;
    }
    .language {
        font-family: brandFont-cat;
    }
    .language_label {
        font-family: brandFont-subcat;
        color: #ffffff;
    }
    .store_logo {
        width: 120px;
        height: auto;
    }
    .pagination >>> .page-link {
        box-shadow: 5px 5px 8px 1px black;
        border: none !important;
        margin: 10px;
        padding: 12px;
        width: 50px;
        font-family: brandFont-subcat !important;
        font-size: 14px;
        cursor: pointer;
        background-color: #080717 !important;
        color: #ffffff !important;
    }
    .pagination >>>.page-link:hover,  .pagination >>>.page-link:focus{
        background-color: #28abe1 !important;
    }

    .imageClass {
        max-width: 100% !important;
        height: auto !important;
    }
    .appsThumb {
        height: 250px !important;
        width: 250px !important;
        max-height: auto !important;
        max-width: 100% !important;   
    }
    @media screen and (max-width: 768px) {
        .card_description  {
            display: none;
        }
        .status_label {
            display: none;
        }
    }
</style>