<template>
    <div id="general_subcat_content" class="py-2 px-4 px-md-5">
        <Loader :state="loader_state"></Loader>
        <div class="row mt-2 align-items-center">
            <div class="col-md-4 ">
                <div class="row mb-md-4">
                    <div class="col-md-12">
                        <div class="sidebar_toggler">
                            <img src="../../assets/icons/menu.svg" class="img-fluid sidebar_icon float-md-left float-right" v-b-toggle.main_sidebar alt="menu">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="content_title">
                            <p class="subcat_content_title text-white text-uppercase">{{categoryDetail.category_details ? categoryDetail.category_details.name : null }}</p>
                            <div class="sub_cat_title text-white">
                                <img class="sub_cat_title_icon" src="../../assets/icons/arrow-white.svg" alt="sub_cat_title_icon">
                                {{categoryDetail.name}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 text-center">
                <router-link :to="{name: 'home'}">
                    <img class="subcat_content_logo" src="../../assets/logos/Al-haq-Logo-selected.svg" alt="">
                </router-link>
            </div>
            <div class="col-md-4">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="sub_cat_detail_group">
                    <p class="sub_cat_desciption text-white my-3">
                        {{categoryDetail.description}}
                    </p>
                </div>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-md-12 p-0 p-md-auto">
                <b-tabs
                    v-model="tabIndex"
                    justified
                    active-tab-class="active_tab_class"
                    active-nav-item-class="active_nav_item"
                >
                    <b-tab title-link-class="mx-md-4 mx-0 my-2 my-md-0">
                       <template v-slot:title>
                           <p class="tab-title text-uppercase">
                               <span class="tab-title-text">Video Series</span>
                               <span class="tab-data-count">{{ subCatPlaylistsData.length }}</span>
                               <img v-if="tabIndex === 0" class="tab_icon mb-1 ml-1" src="../../assets/icons/series.svg" alt="videos_icon">
                               <img v-else class="tab_icon mb-1 ml-1" src="../../assets/icons/video-series-white.svg" alt="videos_icon">
                               <span class="mobile-titles">Videos</span>
                           </p>
                       </template>

                        <g-tab content="playlist" :detail=categoryDetail :data="subCatPlaylistsData"></g-tab>

                    </b-tab>
                    <b-tab title-link-class="mx-md-4 mx-0 my-2 my-md-0">
                        <template v-slot:title>
                            <p class="tab-title text-uppercase">
                                <span class="tab-title-text">Audio Series</span>
                                <span class="tab-data-count">{{ subCatAudioCollectionsData.length }}</span>
                                <img v-if="tabIndex === 1" class="tab_icon mb-1 ml-1" src="../../assets/icons/audio.svg" alt="audio_icon">
                                <img v-else class="tab_icon mb-1 ml-1" src="../../assets/icons/audio-white.svg" alt="audio_icon">
                                <span class="mobile-titles">Audios</span>
                            </p>
                        </template>

                        <g-tab content="audio" :detail=categoryDetail :data="subCatAudioCollectionsData"></g-tab>

                    </b-tab>
                    <b-tab title-link-class="mx-md-4 mx-0 my-2 my-md-0">
                        <template v-slot:title>
                            <p class="tab-title text-uppercase">
                                <span class="tab-title-text">Articles</span>
                                <span class="tab-data-count">{{ subCatArticlesData.length }}</span>
                                <img v-if="tabIndex === 2" class="tab_icon mb-1 ml-1" src="../../assets/icons/articles.svg" alt="article_icon">
                                <img v-else class="tab_icon mb-1 ml-1" src="../../assets/icons/article-white.svg" alt="article_icon">
                                <span class="mobile-titles">Articles</span>
                            </p>
                        </template>

                        <a-tab content="Articles" :detail="categoryDetail" :data="subCatArticlesData"></a-tab>

                    </b-tab>
                    <b-tab title-link-class="mx-md-4 mx-0 my-2 my-md-0">
                        <template v-slot:title>
                            <p class="tab-title text-uppercase">
                                <span class="tab-title-text">Books</span>
                                <span class="tab-data-count">{{ subCatBooksData.length }}</span>
                                <img v-if="tabIndex === 3" class="tab_icon mb-1 ml-1" src="../../assets/icons/books.svg" alt="books_icon">
                                <img v-else class="tab_icon mb-1 ml-1" src="../../assets/icons/book-white.svg" alt="books_icon">
                                <span class="mobile-titles">Books</span>
                            </p>
                        </template>
                        <g-tab content="books" :data="subCatBooksData"></g-tab>

                    </b-tab>
                    <b-tab title-link-class="mx-md-4 mx-0 my-2 my-md-0">
                        <template v-slot:title>
                            <p class="tab-title text-uppercase">
                                <span class="tab-title-text">Apps</span>
                                <span class="tab-data-count">{{ subCatAppsData.length }}</span>
                                <img v-if="tabIndex === 4" class="tab_icon mb-1 ml-1" src="../../assets/icons/app-blue.svg" alt="apps_icon">
                                <img v-else class="tab_icon mb-1 ml-1" src="../../assets/icons/app.svg" alt="apps_icon">
                                <span class="mobile-titles">Apps</span>
                            </p>
                        </template>

                        <g-tab content="apps" :data="subCatAppsData"></g-tab>

                    </b-tab>

                </b-tabs>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';
    import config from '../../assets/config';
    import Loader from '../shared/alhaq_loader'
    import gTab from './general-tabbed-content';
    import aTab from './article-tabbed-content';

    export default {
        data() {
            return {
                loader_state: false,
                tabIndex: 0,
                categoryDetail: {}
            }
        },
        components: {
          'g-tab' : gTab,
          'a-tab' : aTab,
          'Loader': Loader
        },
        mounted() {
        },
        methods: {
            ...mapActions([
                'getSubCatItem',
                'getSubCategoryWithId'
            ])
        },
        computed: {
            ...mapGetters([
                'subCatPlaylistsData',
                'subCatAudioCollectionsData',
                'subCatAppsData',
                'subCatBooksData',
                'subCatArticlesData'
            ])
        },
        watch: {
             '$route.params' : {
                 handler: function () {
                    this.loader_state = true
                    this.getSubCatItem({id: this.$route.params.id}).then(() => {
                        this.loader_state = false
                    }).finally(() => {
                        this.loader_state = false
                    })
                        
                 },
                 immediate: true
             },
            '$route.params.id': {
                 handler: function () {
                    this.loader_state = true
                     this.getSubCategoryWithId({id: this.$route.params.id}).then(response => {
                            this.categoryDetail = response.data.data
                            this.loader_state = false
                     })
                 },
                immediate: true
            },
            '$route.params.tab_index': {
                 handler: function () {
                     this.tabIndex = this.$route.params.tab_index
                 },
                immediate: true
            }
        }

    }
</script>

<style scoped>
    .subcat_content_logo {
        width: 180px !important;
        height: 180px !important;
    }
    .subcat_content_title {
        font-size: 26px !important;
        font-family: brandFont-bold;
        width: fit-content;
    }
    .subcat_content_title::before {
        font-size: 22px;
        content: '';
        margin-right: 15px;
        font-family: brandFont-bold;
        border-left: 8px solid #28abe1;
    }
    .subcat_content_title::after {
        content: ' ';
        margin-top: 5px;
        display: block;
        border: .5px solid white;
    }
    .sub_cat_title {
        color: #28abe1 !important;
        font-family: brandFont-subcat;
        font-size: 22px !important;
    }
    .sub_cat_desciption {
        font-family: brandFont-subcat;
        font-size: 16px;
    }
    .sub_cat_title_icon {
        height: 20px !important;
        width: 20px !important;
    }
    .sidebar_icon {
        width: 40px !important;
        height: 40px !important;
        cursor:pointer !important;
    }
    .sidebar_icon:focus {
        outline: none !important;
    }
    .tab-title {
        position: relative;
        text-align: center;
        font-size: 18px;
        font-family: brandFont-cat;
        margin: auto;
        width: fit-content;
        transition: color .5s;
    }
    .tab_icon {
        height: 25px !important;
        width: 25px !important;
    }
    .tabs >>> .nav-tabs {
        border: none !important;
    }
    .tabs >>> .nav-tabs .nav-link ,
    .tabs >>> .nav-tabs .nav-link.active
    {
        outline: none !important;
        border: none !important;
        background-color: transparent !important;
        transition: border-bottom .5s;
    }
    .tabs >>> .nav-tabs .nav-link.active {
        color: #28abe1 !important;
        border-bottom: 4px solid #28abe1 !important;
    }
    .tabs >>> .nav-tabs a {
        color: #ffffff !important;
    }
    .tabs >>> .nav-tabs .nav-link:active {
        border-bottom: 4px solid #28abe1 !important;
    }
    .tabs >>> .nav-tabs .nav-item {
        border-right: 1px solid white;
    }
    .tabs >>> .nav-tabs .nav-item:last-child {
        border: none !important;
    }
    .tabs >>> .tab-content {
        margin-top: 50px !important;
    }
    .tab-data-count {
        display: none;
        position: absolute;
        top: 0px;
        left: 105%;
        color: white;
        border-radius: 5px;
        padding: 1px 3px;
        font-size: 14px;
        background-color: #28abe1;
    }

    @media screen and (max-width: 1024px) {
        .tabs >>> .nav-tabs .nav-item {
          border: none !important;
        }
    }
    .mobile-titles {
            display: none;
    }

    @media screen and (max-width: 768px) {
        .tab-title > .tab-title-text {
           display: none;
        }
        .tab-data-count {
            display: none;
        }
        .mobile-titles {
            display: block;
            font-size: 8px;
        }
    }

</style>