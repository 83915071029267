<template>
    <div id="sub-cat-view">
            <g-subcat-content></g-subcat-content>
    </div>
</template>

<script>
    import general_subcat_content from "../components/public/general_subcat_content";
    export default {
        components: {
            'g-subcat-content': general_subcat_content
        },
    }

</script>

<style scoped>

</style>