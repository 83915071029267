<template>
    <div id="article-tabbed-content"  class="mx-4">
        <div class="row justify-content-center">
            <div class="col-md-4">
                <b-input-group class="search_group my-2" size="md">
                    <b-form-input v-model="search_text" class="article_search py-2" :placeholder="'Search Articles...'">
                    </b-form-input>
                    <b-input-group-append>
                        <b-button class="article_search_button">
                            <b-icon-search></b-icon-search>
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </div>
        </div>
       <div v-if="computedData.length" class="row my-4 py-2 cards-row">
           <div class="col-md-3"  v-for="(d, index) in computedData" :key="index">
               <b-card class="block_cards my-3 bg-transparent" @click="$router.push({
                       name: 'read-article',
                       params: {
                           art_id: d.id,
                           subcat_id: d.subcategory_details ? d.subcategory_details.id : '',
                           tab_index: 2
                       }
                   })">
                   <b-card-title class="card_title"> {{d.title}}</b-card-title>
                   <div class="latest-card-img-wrapper">
                       <b-card-img  :src=baseURL+d.thumbnail></b-card-img>
                   </div>
                   <b-card-sub-title class="text-truncate my-2">{{d.author_details.name}}</b-card-sub-title>
                   <b-card-text class="text-white card_date_label">
                       Date: <span class="card_date">{{d.date}}</span>
                   </b-card-text>
               </b-card>
           </div>
       </div>
       <div v-if="!data.length" class="text-white text-center">
            <span class="text-capitalize"> No {{ content }}</span> available under this category
        </div>
        <div v-if="data.length" class="row mt-5">
            <div class="col-md-12">
                <b-pagination
                        align="center"
                        v-model="pagination.current_view"
                        :total-rows="data.length-1"
                        :per-page="pagination.per_view"
                        first-text="First"
                        next-text="Next"
                        prev-text="Prev"
                        last-text="Last"
                ></b-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import config from "../../assets/config";
    import { mapActions } from 'vuex'
    export default  {
        props: {
            data: {
                type: Array,
                default: []
            },
            content: {
                type: String,
                default: ''
            },
            detail: Object
        },
        data() {
            return {
                loader_state: false,
                baseURL: config.baseURL,
                pagination: {
                    per_view: 16,
                    current_view: 1,
                },
                search_text: ''
            }
        },
        computed: {
            computedData () {
                return this.data.filter((item, index) => (
                    item.title.toLowerCase().includes(this.search_text.toLowerCase()) &&
                    index >= this.pagination.per_view * (this.pagination.current_view - 1) &&
                    index <= this.pagination.per_view * this.pagination.current_view
                ))
            }
        }
    }

</script>

<style scoped>

    .cards-row {
        border: 1px dashed white;
        border-width: thin;
    }
    .card {
        border: none;
    }
    .card .card-title {
        color: #28abe1;
        font-weight: bolder;
    }
    .card_date {
        color: #28abe1;
    }
    .card .card-subtitle {
        color: white !important;
    }
    .card-title {
        font-size: 16px;
    }
    .card_date_label {
        font-size: 14px;
    }
    .card-subtitle {
        font-size: 14px;
    }
    .card_date {
        font-size: 14px;
    }
    .card_category {
        color: #8a8d91 !important;
        background-color: transparent !important;
        box-shadow: none !important;
        font-size: 12px !important;
    }
    .card:hover {
        overflow: hidden;
        cursor: pointer;
    }
   
    .sidebar_icon {
        width: 60px !important;
        height: 60px !important;
        cursor:pointer !important;
    }
    .pagination >>> .page-link {
        box-shadow: 5px 5px 8px 1px black;
        border: none !important;
        margin: 10px;
        padding: 12px;
        width: 50px;
        font-family: brandFont-subcat !important;
        font-size: 14px;
        cursor: pointer;
        background-color: #080717 !important;
        color: #ffffff !important;
    }
    .pagination >>>.page-link:hover,  .pagination >>>.page-link:focus{
        background-color: #28abe1 !important;
    }
    .article_search {
        background-color: transparent !important;
        color: white !important;
        box-shadow: none !important;
        border-radius: 0 !important;
        font-family: brandFont-subcat;
    }
    .article_search:focus {
        border:1px solid #28abe1 !important;
    }
    .article_search_button {
        background-color: #28abe1 !important;
        border:1px solid #28abe1 !important;
        box-shadow: none !important;
        color: black !important;
        border-radius: 0 !important;
    }
    .latest-card-img-wrapper {
        overflow: hidden;
    }

</style>